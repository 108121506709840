<template>
  <nav class="flex justify-center w-full my-2 sticky top-0 p-1 z-10">
    <div class="flex w-full max-w-4xl justify-between md:px-1">
      <input
        type="text"
        placeholder="&#x1F50D; Buscar"
        class="rounded-full bg-gray-100 border border-gray-300 w-11/12 py-1 px-2 focus:outline-none shadow"
      />
      <button class="w-10 text-gray-800 focus:outline-none rounded-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class=""
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
          />
        </svg>
      </button>
    </div>
  </nav>
</template>

<script></script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
