<template>
  <footer class="bg-gray-800 flex w-full justify-center">
    <div
      class="max-w-2xl w-full h-12 flex  justify-between items-center px-2 text-pink-500 "
    >
      <div class="w-1/2 sm:w-1/3 flex justify-around items-center">
        <a><img src="" class="w-8"/></a>
        <p class="text-gray-200 text-sm">
          &copy; Fast<span class="text-pink-500">Menu</span>
        </p>
      </div>
      <div class="w-1/2 sm:w-1/3 flex justify-around items-center text-xl">
        <a><i class="fab fa-facebook-f"></i></a>
        <a><i class="fab fa-twitter"></i></a>
        <a><i class="fab fa-instagram"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
